import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function useManageRegisterModal({
  openRegistrationModal,
  registerNowCanBeOpened,
  lotUniqueId,
}: {
  openRegistrationModal: () => void
  registerNowCanBeOpened: boolean
  lotUniqueId: string | number
}) {
  const router = useRouter()

  useEffect(() => {
    if (
      registerNowCanBeOpened &&
      router.query.registerNow &&
      router.query.registerNow === `${lotUniqueId}`
    ) {
      openRegistrationModal()

      const queryParams = Object.assign({}, router.query)
      delete queryParams.registerNow

      router.replace(
        {
          pathname: router.pathname,
          query: queryParams,
        },
        undefined,
        { scroll: false, shallow: true }
      )
    }
  }, [registerNowCanBeOpened, lotUniqueId, router, openRegistrationModal])
}

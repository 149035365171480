import Cookies from 'js-cookie'

import {
  createAuthenticatedAxiosClient,
  isAuthenticatedAxiosError,
} from '@nx/axios-client'
import { BonhamsCookies, EBonhamsCookies } from '@nx/global-types'
import { logger } from '@nx/utils/logger'

import {
  RegisteredForAuction,
  RegisteredForAuctionResponse,
} from './userIsRegisteredForAuction.types'

export async function getUserIsRegisteredForAuction(
  baseUrl: string,
  auctionId: number,
  accessTokenSSR?: BonhamsCookies['bonhams_access'],
  refreshTokenSSR?: BonhamsCookies['bonhams_token']
): Promise<RegisteredForAuction | null> {
  if (!accessTokenSSR && !Cookies.get(EBonhamsCookies.access)) {
    return null
  }

  const authenticatedAxiosClient = createAuthenticatedAxiosClient({
    accessTokenSSR,
    refreshTokenSSR,
    baseURL: baseUrl,
  })

  try {
    const response =
      await authenticatedAxiosClient.get<RegisteredForAuctionResponse>(
        `/api/sale/${auctionId}/registration/`
      )

    return response.data[0]
  } catch (error) {
    if (isAuthenticatedAxiosError(error)) {
      logger.error(
        error,
        `Error on ${authenticatedAxiosClient.defaults.baseURL}/api/sale/${auctionId}/registration/ - status code: ${error.status}`
      )
    } else {
      logger.error(
        error,
        `getUserIsRegisteredForAuction Error auction: ${auctionId}`
      )
    }

    return null
  }
}

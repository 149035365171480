import getConfig from 'next/config'
import { useContext } from 'react'

import { useCurrencyRates } from '@nx/api/hooks'
import { decodeHtml } from '@nx/decode-html'
import { useGetLaunchDarklyFlags } from '@nx/external/launch-darkly-client'
import { EAuctionType, ELotStatus, LotStatus } from '@nx/global-types'
import {
  currencyFormat,
  formatNumberWithCommas,
  roundToSignificantValue,
} from '@nx/helpers'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import { UserContext } from '@web/context/UserInfoProvider'
import { NOT_DEFINED_CURRENCY } from '@web/helpers/enums'

import {
  ConvertedEstimationPriceContainer,
  EstimationPriceContainer,
  PriceContainer,
} from './LotPrice.styles'
import translate from './LotPrice.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text, textHTML } = getTranslate(translate)

interface PremiumPriceProps {
  hammerPrice: number
  hammerPremium: number
  currencySymbol: string
  highlightWon?: boolean
  publishResults?: boolean
}

export function PremiumPrice({
  hammerPrice,
  hammerPremium,
  currencySymbol,
  highlightWon,
  publishResults = true,
}: PremiumPriceProps) {
  const hasHammerPremium = Boolean(Math.round(hammerPremium))

  const actionText = highlightWon ? text('lotPrice.won') : text('lotPrice.sold')
  const isPremiumIncluded = Math.round(hammerPremium) >= Math.round(hammerPrice)
  let key:
    | 'lotPrice.soldFor'
    | 'lotPrice.soldForIncPremium'
    | 'lotPrice.soldAlone' = 'lotPrice.soldFor'

  if (!publishResults) {
    key = 'lotPrice.soldAlone'
  } else if (hasHammerPremium && isPremiumIncluded) {
    key = 'lotPrice.soldForIncPremium'
  }

  const soldPrice = hasHammerPremium ? hammerPremium : hammerPrice

  return (
    <>
      {textHTML(key, {
        action: actionText,
        ...(publishResults
          ? {
              soldPrice: (
                <PriceContainer key="1">
                  {`${decodeHtml(currencySymbol)}${formatNumberWithCommas(
                    soldPrice
                  )}`}
                </PriceContainer>
              ),
            }
          : {}),
      })}
    </>
  )
}

interface EstimationPriceProps {
  auctionType?: EAuctionType
  sLotStatus?: ELotStatus
  estimateHigh?: number
  estimateLow?: number
  GBPLowEstimate?: number
  GBPHighEstimate?: number
  currencySymbol: string
  currencyISO?: string
  withEstimate?: boolean
}

export function EstimationPrice({
  auctionType,
  sLotStatus,
  estimateLow,
  estimateHigh,
  GBPLowEstimate,
  GBPHighEstimate,
  currencySymbol,
  withEstimate = true,
  currencyISO,
}: EstimationPriceProps) {
  const { data: flags } = useGetLaunchDarklyFlags(
    publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
  )
  const { currencyConfig } = useContext(UserContext)
  const defaultCurrencyISO =
    currencyConfig?.userSelectedCurrency &&
    currencyConfig.userSelectedCurrency !== NOT_DEFINED_CURRENCY
      ? currencyConfig.userSelectedCurrency
      : undefined
  const currencyLocale = currencyConfig?.userLocaleCurrency

  const { data: currencyRates } = useCurrencyRates(
    publicRuntimeConfig.WEB_API_URL
  )

  const showDefaultCurrencyEstimate =
    !!GBPLowEstimate &&
    !!GBPHighEstimate &&
    !!auctionType &&
    !!sLotStatus &&
    sLotStatus !== LotStatus.sold &&
    sLotStatus !== LotStatus.unsold &&
    sLotStatus !== LotStatus.withdrawn &&
    !!currencyRates &&
    !!currencyISO &&
    !!currencyLocale &&
    !!defaultCurrencyISO &&
    defaultCurrencyISO !== currencyISO &&
    defaultCurrencyISO !== NOT_DEFINED_CURRENCY &&
    !!currencyRates.rates[defaultCurrencyISO]

  const hasAnEstimate = estimateHigh && estimateLow

  if (hasAnEstimate) {
    return (
      <>
        <EstimationPriceContainer>
          {textHTML(
            withEstimate ? 'lotPrice.estimate.lowHigh' : 'lotPrice.lowHigh',
            {
              estimateLow: (
                <PriceContainer key="1">
                  {`${decodeHtml(currencySymbol)}${formatNumberWithCommas(
                    estimateLow
                  )}`}
                </PriceContainer>
              ),
              estimateHigh: (
                <PriceContainer key="2">
                  {`${decodeHtml(currencySymbol)}${formatNumberWithCommas(
                    estimateHigh
                  )}`}
                </PriceContainer>
              ),
            }
          )}
        </EstimationPriceContainer>
        {showDefaultCurrencyEstimate ? (
          <ConvertedEstimationPriceContainer
            {...SmartlingAttributes.noTranslate}
          >
            {textHTML('lotPrice.lowHigh', {
              estimateLow: (
                <PriceContainer key="1">
                  {flags?.feature_currency_localization ? (
                    currencyFormat({
                      value: roundToSignificantValue(
                        GBPLowEstimate *
                          currencyRates.rates[defaultCurrencyISO].rate
                      ),
                      iso: currencyISO,
                      locale: currencyLocale,
                      bonhamsCode:
                        currencyRates.rates[defaultCurrencyISO].bonhams_code,
                    })
                  ) : (
                    <>
                      {currencyRates.rates[defaultCurrencyISO].bonhams_code}
                      {formatNumberWithCommas(
                        roundToSignificantValue(
                          GBPLowEstimate *
                            currencyRates.rates[defaultCurrencyISO].rate
                        )
                      )}
                    </>
                  )}
                </PriceContainer>
              ),
              estimateHigh: (
                <PriceContainer key="2">
                  {flags?.feature_currency_localization ? (
                    currencyFormat({
                      value: roundToSignificantValue(
                        GBPHighEstimate *
                          currencyRates.rates[defaultCurrencyISO].rate
                      ),
                      iso: currencyISO,
                      locale: currencyLocale,
                      bonhamsCode:
                        currencyRates.rates[defaultCurrencyISO].bonhams_code,
                    })
                  ) : (
                    <>
                      {currencyRates.rates[defaultCurrencyISO].bonhams_code}
                      {formatNumberWithCommas(
                        roundToSignificantValue(
                          GBPHighEstimate *
                            currencyRates.rates[defaultCurrencyISO].rate
                        )
                      )}
                    </>
                  )}
                </PriceContainer>
              ),
            })}
          </ConvertedEstimationPriceContainer>
        ) : null}
      </>
    )
  }

  return null
}

interface LotPriceProps {
  publishResults?: boolean
  lReferDept?: boolean
  hammerPrice: number
  hammerPremium: number
  currencySymbol: string
  estimateHigh?: number
  estimateLow?: number
  highlightWon?: boolean
}

export function LotPrice({
  hammerPrice,
  hammerPremium,
  currencySymbol,
  lReferDept,
  estimateHigh,
  estimateLow,
  publishResults = true,
  highlightWon = false,
}: LotPriceProps) {
  if (hammerPremium !== 0 || hammerPrice !== 0) {
    return (
      <PremiumPrice
        hammerPrice={hammerPrice}
        hammerPremium={hammerPremium}
        currencySymbol={currencySymbol}
        highlightWon={highlightWon}
        publishResults={publishResults}
      />
    )
  }

  if (lReferDept) {
    return <>{text('lotPrice.estimate.referToDept')}</>
  }

  return (
    <EstimationPrice
      currencySymbol={currencySymbol}
      estimateLow={estimateLow}
      estimateHigh={estimateHigh}
    />
  )
}

import getConfig from 'next/config'

import { useGetUserRegistrations } from '@nx/api'

import { useGetUser } from '@web/helpers/api/user'

const { publicRuntimeConfig } = getConfig()

export function useUserRegistrations() {
  const { data: user } = useGetUser()

  return useGetUserRegistrations({
    baseUrl: publicRuntimeConfig.CLIENT_SERVICE_URL,
    clientNo: user?.customerId,
  })
}

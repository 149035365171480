import styled, { css } from 'styled-components'

import { ExclamationPointIcon } from '@nx/fire/assets'
import { FireButton } from '@nx/fire/button'
import { Body } from '@nx/fire/typography'

export const StyledLotCardSeparator = styled.hr`
  display: none;
  width: 50%;
  border: 0;
  ${({ theme }) => theme.helpers.topSeparationLine}
  height: 1px;
  box-sizing: border-box;
  margin: 30px auto;
`

export const LotCardCompactContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 1rem;
  position: relative;
  padding: 5px;
  border: solid 1px transparent;

  &:hover,
  &:active,
  &:focus {
    ${({ theme }) => theme.helpers.borders};
  }
`

export const LotCardContainer = styled(LotCardCompactContainer)`
  grid-template-columns: 100px 1fr;

  ${({ theme }) => theme.media.up.md} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    height: 100%;
  }
`

export const LotCardCompactMainContainer = styled.div<{
  $isBackTopShown?: boolean
}>`
  ${({ theme }) => css`
    &::after,
    &::before {
      content: '';
      display: table;

      ${theme.media.up.md} {
        display: block;
      }
    }

    * {
      ${theme.helpers.noLinkUnderline}
    }
  `}
`

export const LotCardMainContainer = styled(LotCardCompactMainContainer)<{
  $isBackTopShown?: boolean
}>`
  ${({ theme, $isBackTopShown }) => css`
    ${$isBackTopShown
      ? css`
          &:not(:last-child) {
            ${StyledLotCardSeparator} {
              display: block;
            }

            ${theme.media.up.md} {
              ${StyledLotCardSeparator} {
                display: none;
              }
            }
          }
        `
      : css`
          ${StyledLotCardSeparator} {
            display: block;
          }

          ${theme.media.up.md} {
            ${StyledLotCardSeparator} {
              display: none;
            }
          }
        `}
  `}
`

export const StyledActionButton = styled(FireButton)`
  ${({ theme }) => theme.fontStyles.sourceSans.medium}
  text-decoration: underline;
  margin: 0;
`

export const StyledAttentionIcon = styled(ExclamationPointIcon)`
  ${({ theme }) => css`
    border-color: ${theme.colours.grey['90']};
    color: ${theme.colours.grey['90']};
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-radius: 100%;
    height: 15px;
    padding: 2px;
    width: 15px;
  `}
`

export const StyledBidNowHint = styled(Body)`
  color: ${({ theme }) => theme.colours.grey['90']};
  margin: 0;
`

export const StyledBidNowHintDescription = styled(Body)`
  margin: 0;
`

export const StyledActionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

import { useQuery } from '@tanstack/react-query'

import { getUserIsRegisteredForAuction } from './getUserIsRegisteredForAuction'

export function useGetUserIsRegisteredForAuction({
  auctionId,
  userId,
  baseUrl,
}: UseGetUserIsRegisteredForAuctionProps) {
  const { data, status, error } = useQuery({
    queryKey: ['userIsRegisteredForAuction', { auctionId, userId }],
    queryFn: async () => {
      const response = await getUserIsRegisteredForAuction(baseUrl, auctionId)

      if (response) {
        return {
          userRegistered: true,
          approvedToBid: response.approvedToBid,
        }
      } else {
        throw new Error('User is not registered')
      }
    },
    retry: 10,
    retryDelay: 1_000,
    enabled: Boolean(userId),
    refetchOnWindowFocus: false,
  })

  return {
    userRegistered: (data && data.userRegistered) || false,
    approvedToBid: data?.approvedToBid,
    status,
    error,
  }
}

export interface UseGetUserIsRegisteredForAuctionProps {
  auctionId: number
  userId?: number
  baseUrl: string
}
